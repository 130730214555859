import { Button, Input, Pagination, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { client } from '../../useRequest';
import { PlusCircle, PlusIcon } from 'lucide-react';
import CustomModal from '../CustomModal';

interface DatatableProps {
  url: String,
  query?: any,
  columns: any,
  scroll?: any,
  filters?: any,
  searchFilter?: boolean,
  heading?: string,
  addBody?: any,
  mutate?: any
}
const Datatable: React.FC<DatatableProps> = ({ url, query, columns, scroll = 500, filters, searchFilter = true, heading, addBody, mutate }) => {
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 5,
    sortOrder: 'DESC',
    sortProperty: 'createdTime',
    ...filters
  } as any);
  const [textSearch, setTextSearch] = useState('');
  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await client(`${url}?` + new URLSearchParams(pagination))
      setData(response?.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [pagination, mutate])

  const handleSort = (pagi: any, filters: any, sorter: any) => {
    setPagination({ ...pagination, sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC', sortProperty: sorter.column?.dataIndex ?? 'createdTime' })
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setPagination({ ...pagination, textSearch: textSearch })
    }
  }

  return (
    <div className='flex flex-col overflow-x-hidden'>
      <div className="flex justify-between">

        <div className='flex justify-start space-x-3'>
          {searchFilter && (
            <>
              <Input className='max-w-[300px] mb-5' placeholder='Search...' value={textSearch} onKeyDown={handleKeyDown} onChange={(e: any) => setTextSearch(e.target.value)} />
              {pagination?.textSearch && (<span>Search Key: {pagination?.textSearch}</span>)}
            </>
          )}
        </div>
        {!!addBody &&
          (
            addBody
          )
        }
      </div>
      <Table pagination={false}
        scroll={{ y: scroll }}
        loading={{
          spinning: loading,
          indicator: <Spin size="large" style={{ color: 'green' }} />,
        }}
        className='w-full drop-shadow-sm shadow-md'
        dataSource={data?.data}
        columns={columns}
        footer={() => (
          <Pagination onChange={(page: any) => setPagination({ ...pagination, page: page - 1 })} className='flex justify-end' defaultCurrent={pagination.page} total={data.totalElements} pageSize={pagination.pageSize} />
        )}
        rowKey={(record: any) => record.id.id}
        onChange={handleSort}
      />
    </div>
  )
}

export default Datatable
