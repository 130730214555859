import { Button, Input, Modal, Pagination, Spin, Table, Form as CustomForm, } from 'antd'
import React, { useEffect, useState } from 'react'
import { client } from '../../useRequest';
import { FileUpIcon, PlusCircle, PlusIcon, Upload } from 'lucide-react';
import CustomModal from '../CustomModal';
import { client2 } from '../../operationalRequest';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MapLeaf from '../MapLeaf';
import * as XLSX from 'xlsx';
import LoadingButton from '../LoadingButton';
import csv from 'csv-parser';
import Papa from 'papaparse';
import toastr from "toastr";
import _ from 'lodash';



interface OperationalDatatableProps {
    url: String,
    query?: any,
    columns: any,
    scroll?: any,
    filters?: any,
    searchFilter?: boolean,
    heading?: string,
    addBody?: any,
    mutate?: any
}
const OperationalDatatable: React.FC<OperationalDatatableProps> = ({ url, query, columns, scroll = 500, filters, searchFilter = true, heading, addBody, mutate }) => {
    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 5,
        sortOrder: 'DESC',
        sortProperty: 'created_at',
        ...filters
    } as any);
    const [locations, setLocations] = useState([] as any)
    const [textSearch, setTextSearch] = useState('');
    const [csvData, setCsvData] = useState({} as any);
    const [toggle, setToggle] = useState(false);
    const [changed, setChanged] = useState({} as any)
    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await client2(`${url}?` + new URLSearchParams(pagination))
            setData(response?.data)
            setLoading(false)
            setLocations(response?.data?.data?.data.filter((it: any) => {
                // console.log('attributes', !!it.attributes && !!it.attributes?.latitude, it.attributes)
                return !!it.attributes && it.attributes?.latitude && it.attributes?.longitude
            }).map((item: any, i: number) => {
                return {
                    lat: item.attributes?.latitude,
                    lng: item.attributes?.longitude,
                    name: item.name
                }
            }))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData()
    }, [pagination, mutate, changed])

    const handleSort = (pagi: any, filters: any, sorter: any) => {
        setPagination({ ...pagination, sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC', sortProperty: sorter.column?.dataIndex ?? 'created_at' })
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setPagination({ ...pagination, textSearch: textSearch })
        }
    }

    const handleUpload = (e: any) => {
        console.log('hello')
        setCsvData([]);
        setToggle(false);
        const file = e.target.files[0];
        e.target.value = ''
        if (!file) return;

        // const workbook = XLSX.read(file, { type: 'binary' });
        const reader = new FileReader();
        reader.onload = (evt: any) => {
            const binaryString = evt.target.result;

            // Convert binary string to text
            const textData = new TextDecoder().decode(binaryString);

            // Parse CSV data using papaparse with custom delimiter
            Papa.parse(textData, {
                delimiter: ';', // Set your custom delimiter here
                complete: (results) => {
                    console.log(results.data); // Output the parsed CSV data
                    let newData = results.data;
                    const keys = newData.shift() 
                    setCsvData({keys, data:newData})
                    setToggle(true)
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
        };
        reader.readAsArrayBuffer(file);
    
}

if (data.length === 0) {
    return <>loading...</>
}

return (
    <div className='flex flex-col overflow-x-hidden'>
        <MapLeaf locations={locations} />
        <br />
        <div className="flex justify-between">

            <div className='flex justify-start space-x-3'>
                {searchFilter && (
                    <>
                        <Input className='max-w-[300px] mb-5' placeholder='Search...' value={textSearch} onKeyDown={handleKeyDown} onChange={(e: any) => setTextSearch(e.target.value)} />
                        {pagination?.textSearch && (<span>Search Key: {pagination?.textSearch}</span>)}
                    </>
                )}
            </div>
            <div className="flex justify-end space-x-2 mr-3">
                {!!addBody &&
                    (
                        addBody
                    )
                }

                <label htmlFor="file-upload" className="file-upload-label">
                    <Upload className="upload-icon cursor-pointer" />
                    <input
                        id="file-upload"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e: any) => {
                            handleUpload(e)
                        }}
                    
                        style={{ display: 'none' }}
                    />
                </label>
            </div>
        </div>
        <Table pagination={false}
            scroll={{ y: scroll }}
            loading={{
                spinning: loading,
                indicator: <Spin size="large" style={{ color: 'green' }} />,
            }}
            className='w-full drop-shadow-sm shadow-md'
            dataSource={data?.data?.data}
            columns={columns}
            footer={() => (
                <Pagination onChange={(page: any, pageSize: any) => setPagination({ ...pagination, page: page, pageSize })} className='flex justify-end' defaultCurrent={pagination.page} total={data?.data?.total} pageSize={pagination.pageSize} />
            )}
            rowKey={(record: any) => record.id}
            onChange={handleSort}
        />
        {!_.isEmpty(csvData) && <UploadModal mutate={setChanged} data={csvData} open={toggle} setToggle={() => setToggle(false)} />}
    </div>
)
}

export default OperationalDatatable

type UploadModalProps = {
    data: any
    open: boolean
    setToggle: any
    mutate: any
}
const UploadModal: React.FC<UploadModalProps> = ({ data, open, setToggle, mutate }) => {
    const [loading, setLoading] = useState(false);
    const [form] = CustomForm.useForm();
    const onSubmit = async (values: any) => {
        try {
                const response = await client2.post('api/building/bulk-upload', {
                ...values, data: data?.data, headData: data.keys
            })
            if(response.data?.status === 200){
                mutate({ data: response?.data });
                setToggle(false)
                toastr.success(response?.data?.message);
            }else{
                toastr.error(response?.data?.message);
            }
        } catch (e: any) {
            toastr.success(e.message);
        }

    }

    console.log('fasdfa operation', )

    return (
        <Modal width={800}  okButtonProps={{ style: { display: 'none' } }} title="Bulk Upload" open={open} onCancel={() => setToggle(!open)}>
            <CustomForm
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                onFinish={onSubmit}
                autoComplete="off"
                layout="vertical"
            >

                {(data?.keys).map((item: any, i: number) => {
                    return (
                        <CustomForm.Item key={i}
                            label={`Import ${item} as `}
                            name={['colname', `${item}`]}
                        >
                            <Input />
                        </CustomForm.Item>

                    )
                })}


                <CustomForm.Item>
                    <LoadingButton loading={loading} title={"Update"} />
                </CustomForm.Item>
            </CustomForm>
        </Modal>
    )
}