
import Layout from '../../components/layouts/Layout'
import Datatable from '../../components/datatable/Datatable';

const Devices = () => {



  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Device Profile',
      dataIndex: 'type',
      key: 'type',
      sorter: true
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      sorter: true
    },

    {
      title: 'State',
      dataIndex: 'active',
      render: (row: any) => (
        <div className={`${!row ? 'bg-red-300' : 'bg-green-300'} px-2 py-1 rounded w-fit`} >{!!row ? 'Active' : 'Inactive'}</div>
      ),
      key: 'active',
    },
    {
      title: 'Customer Name',
      dataIndex: 'ownerName',
      key: 'ownerName',
      sorter: true
    },

  ];

  
  return (
    <Layout title={'Systems'}>
        <Datatable url="api/deviceInfos/all" columns={columns} filters={{includeCustomers: true}} />
    </Layout>
  )
}

export default Devices