import React from 'react'
import Sidebar, { SidebarItem } from './Sidebar'
import { BarChart, BatteryCharging, Bell, Building2Icon, HomeIcon, LayoutDashboard, PlugZap2Icon, Settings, SunIcon } from 'lucide-react'

const SidebarComponent = () => {
  
  return (
    <Sidebar>
        <SidebarItem icon={<LayoutDashboard size={20}/>} text="Dashboard" />
        {/* <SidebarItem icon={<HomeIcon size={20}/>}  text="House" /> */}
        <SidebarItem icon={<SunIcon size={20}/>}  text="Forecast" />
        <SidebarItem icon={<PlugZap2Icon size={20}/>}  text="Systems" />
        <SidebarItem icon={<Bell size={20}/>}  text="Notifications" />
        <SidebarItem icon={<Building2Icon size={20}/>}  text="Buildings" />
        <SidebarItem icon={<BarChart size={20}/>}  text="Reports" />
        <SidebarItem icon={<Settings size={20}/>}  text="Setting" />
    </Sidebar>
  )
}

export default SidebarComponent