import { Select } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'

type SelectItemsProps = {
    placeholder?: string
    absoluteurl?: string;
    relativeurl?: string;
    onChange?: any
}
const SelectItems: React.FC<SelectItemsProps> = ({absoluteurl, relativeurl, onChange, placeholder = 'Select'}) => {

    const [options, setOptions] = useState([] as any)
    const [loading, setLoading] = useState(true);
    const fetchData = async() => {
        try{
            setLoading(true)
            const response = await axios.get('https://solar.alitiq.com/pv_systems/list/12?response_format=json', {headers: {'x-api-key': '7dOQWE1hGh38CbKfIjlVQZ6IaRk2yWM8qJ1ZSWYi'}})
            // console.log(response);
            setOptions(response?.data?.index.map((item:any, i: number) => {
                return {value: item, label: response.data.data[i][4]}
            }))
            setLoading(false)
        }catch(e: any){

        }
    }
    useEffect(() => {
        fetchData()
    },[])

  return (
    <Select className='w-[300px]'
    showSearch
    placeholder={placeholder}
    onChange={(row: any, option: any) => onChange(option)}
    options={options}
    loading={loading}
  />
  )
}

export default SelectItems