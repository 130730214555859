import React from 'react'
import Datatable from './datatable/Datatable'

const Notifications = () => {
    const columns = [
        {
          title: 'Subject',
          dataIndex: 'subject',
          key: 'subject',
        },
        {
            title: 'Text',
            dataIndex: 'text',
            key: 'text',
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },
        
    
      ];
  return (
    <Datatable url="api/notifications" scroll={150} searchFilter={false} columns={columns} filters={{unreadOnly: true}} />
  )
}

export default Notifications