import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import Datatable from '../../components/datatable/Datatable'
import { ArrowBigLeft, ArrowBigRightDash, CrossIcon, Delete, MapPinIcon, SidebarCloseIcon, Trash2Icon, X } from 'lucide-react';
import { Button, Card, Divider, Modal, Popconfirm, Spin } from 'antd';
import { client } from '../../useRequest';
// import L from 'leaflet';
import AddAssets from './AddAssets';
import EditAsset from './EditAsset';
import toastr from "toastr";
import OperationalDatatable from '../../components/datatable/OperationalDatatable';
import LeafMap from '../../components/LeafMap';
import { client2 } from '../../operationalRequest';
import { random } from 'lodash';

const Assets = () => {
  const [split, setSplit] = useState(false)
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any)


  const onDelete = async(id: any) => {
    const response = await client2.delete(`api/building/${id}`)
    // if(response.status === 200){
      setMutate({data: Math.random() });
      toastr.success("Asset Deleted Successfully");
    // }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      sorter: true
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (row: any, record: any) => {
        return <>{record.attributes?.country}</>
      }
    },
    {
      title: 'Street Name',
      dataIndex: 'street_name',
      key: 'street_name',
      render: (row: any, record: any) => {
        return <>{record.attributes?.street_name}</>
      }
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      render: (row: any, record: any) => {
        return <>{record.attributes?.city}</>
      }
    },
    {
      title: 'Facility Manager',
      dataIndex: 'ownerName',
      key: 'ownerName',
      render: (row:any)=> {
        <div></div>
      }

    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (row: any, record: any) => {
        return <div className='flex justify-content-between'>
          <Button disabled={!record.attributes || !record.attributes?.latitude || !record.attributes?.longitude} onClick={() => {
            setSplit(true)
            setAsset(record)
          }} size='small' className={`size-8  rounded-full py-3 flex items-center`} ><MapPinIcon /></Button>
          <EditAsset data={record} mutate={setMutate} />
          <Popconfirm
            title="Delete the building"
            description="Are you sure to delete this building?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size='small' className='bg-gray-400 size-8 rounded-full py-3 flex items-center text-white'><Trash2Icon /></Button>

          </Popconfirm>
        </div>
      }

    }
  ];

  return (
    <Layout title={'Buildings'}>
      
      <div className="flex">
        <OperationalDatatable mutate={mutate} heading='Asset' addBody={<AddAssets mutate={setMutate} />} url="api/building" columns={columns} filters={{ includeCustomers: true }} />
        <AssetAttribute onClick={() => setSplit(false)} asset={asset} expanded={split} />
      </div>
    </Layout>
  )
}

export default Assets


type AssetAttributeProps = {
  asset: any;
  expanded: boolean;
  onClick: any;
}
const AssetAttribute: React.FC<AssetAttributeProps> = ({ asset, expanded, onClick }) => {
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    if(!!asset?.attributes && expanded){
      setLoading(false)
    }
  },[asset, expanded])

  if(!expanded) {
    return (<></>)
  }
  return (
    <Modal okButtonProps={{ style: { display: 'none' } }} width={'auto'} title="Building" open={expanded} onCancel={onClick}>
      <Spin className='flex justify-start' spinning={loading}>
        <Card className='absolute z-[10000] m-5 ml-16 p-2 drop-shadow-md shadow-lg max-w-[300px]'>
          {Object.entries(asset?.attributes || {}).map(([key, value]: any, i) => {
            return (
              <p key={i}>
                <span><strong>{key.toUpperCase()}</strong>: {value} </span>
              </p>
            )
          })}
        </Card>
        {!!asset?.attributes && <LeafMap position={[ asset.attributes.latitude, asset.attributes.longitude]}/>}
      </Spin>
    </Modal>
  )
}