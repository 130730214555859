import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useNavigate,
  } from "react-router-dom";
import Login from './page/guest/Login';
import Dashboard from './page/dashboard/Dashboard';
import Devices from './page/devices/Devices';
import PrivateRoute from './PrivateRoute';
import Page404 from './page/Page404';
import Notifications from './page/notifications/Notifications';
import Assets from './page/assets/Assets';
import Solar from './page/solar/Solar';

const AllRoutes = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      
       <Route element={<PrivateRoute />}>
       <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/systems" element={<Devices/>} />
        <Route path="/notifications" element={<Notifications/>} />
        <Route path="/buildings" element={<Assets/>} />
        <Route path="/forecast" element={<Solar/>} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  </BrowserRouter>
  )
}

export default AllRoutes