import React from 'react'
import Sidebar from './Sidebar'
import SidebarComponent from './SidebarComponent'
import { useDispatch, useSelector } from 'react-redux';
import { LogOut, MoreVertical, Space } from 'lucide-react';
import { Dropdown, MenuProps, Spin } from 'antd';
import { client } from '../../useRequest';
import { logout } from '../../store/slice/authSlice';

const Layout = ({ children, title, loading = false }: any) => {
  const { user } = useSelector((state: any) => state.user);
  return (
    <aside className={`h-screen flex`}>
      <SidebarComponent />
      <div className='w-full mx-auto overflow-x-hidden'>
        <nav className='flex z-10 justify-between drop-shadow-sm shadow-md sticky top-0 bg-white'>
          <h3 className='my-auto px-10 font-semibold'>{title}</h3>
          <div className="flex p-3 space-x-2">
            <div className="w-10 h-10 bg-green-200 rounded-full flex items-center justify-center">
              <span className="text-green-800 font-bold">{(user.firstName + ' ' + user.lastName).split(' ')
                .map((word) => word.charAt(0).toUpperCase())
                .join('')}</span>
            </div>
            <div className='flex items-center overflow-hidden transition-all w-52 mt-1'>
              <div className="leading-4 spa">
                <h4 className="font-semibold">{user.firstName + ' ' + user.lastName}</h4>
                <span className="text-xs text-gray-600">{user?.email}</span>
              </div>
              <DropDownUser />
            </div>
          </div>
        </nav>
        <div className='px-4 py-4'>
          <Spin spinning={loading} tip="Loading..." size="large">
            {children}
          </Spin>
        </div>
      </div>
    </aside>
  )
}

export default Layout


const DropDownUser = () => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    const response = await client.post('api/auth/logout')
    if (response.status === 200) {
      dispatch(logout())
    }
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <a className='flex space-x-4' onClick={handleLogout}>
          <h6>Logout</h6> <LogOut />
        </a>
      ),
      key: '0',
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <MoreVertical className='cursor-pointer mt-1' size={20} />
    </Dropdown>
  )

}