import { client } from "../../useRequest";
import { getUserPending, getUserSuccess, getUserFail } from "../slice/userSlice";


const getUserProfile = () => async (dispatch) => {
    try{
        dispatch(getUserPending);
        const response = await getUser();
        if(response?.status === 200){
            return dispatch(getUserSuccess(response.data))
        }else{
            return dispatch(getUserFail('Fetching user failed'));
        }
    }catch(e){
        return dispatch(getUserFail(e.message));
    }
}

export default getUserProfile;

const getUser = async () => {

    try{
         const response = await client.get(`api/auth/user`)
         console.log('hello', response)
        return response;
    }catch(e){
        
    }
  
}