import React, { useEffect, useState } from 'react'
import { MapContainer as LeafletMap, TileLayer, Marker, MapContainer, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

type LeafMapProps = {
    position: any
}
const LeafMap: React.FC<LeafMapProps> = ({ position }) => {
    const [pose, setPose] = useState([0,0]as any)
    useEffect(() => {
        setTimeout(()=> setPose(position),1000)
    },[position])
    return (
        <LeafletMap key={pose.toString()} center={pose} zoom={16} style={{ height: '500px' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={pose} />
        </LeafletMap>
    )
}

export default LeafMap